@import "custom";

h1, h2, h3 {
  color: $primary;
  text-transform: uppercase;
}

.App {
  text-align: center;

  .page {
    margin-bottom: 50px;

    h2 {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    p, ul {
      text-align: left;
    }

    .intro {
      margin-bottom: 50px;
    }

    .two-col-block-header {
      &:nth-of-type(1) {
        margin-right: 10px;
      }
  
      &:nth-of-type(2) {
        margin-left: 10px;
      }
  
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 96px;
        margin-bottom: 30px;
    
        color: #fff;
        background-color: $primary;
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  padding-top: 43px;
  padding-bottom: 54px;
  background-color: $primary;
  color: #fff;

  .col-copyright {
    text-align: left;
  }

  .col-social {
    display: flex;
    justify-content: flex-end;

    .icon-wrapper {
      position: relative;
      width: 26px;
      height: 26px;
      margin-right: 12px;

      .icon-background {
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;

        background-color: #fff;
      }
    }

    .social-icon {
      position: relative;
      width: 100%;
      height: 100%;

      &.twitter {
        color: #00acee;
      }

      &.linkedin {
        color: #0e76a8;
      }
    }
  }
}
