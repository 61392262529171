.expertise-sub-page {
  .intro-image {
    width: 424px;
    height: 277px;
    object-fit: cover;
  }

  .cta {
    margin-bottom: 100px;
  }

  .columns {
    margin-bottom: 100px;

    h3 {
      margin-bottom: 30px;
    }
  }
}
