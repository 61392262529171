.careers-page {
  .careers-form {
    display: flex;
    flex-flow: column;
    align-items: center;

    input, select {
      // width: 400px;
      margin-bottom: 20px;

      &#careerCountry {
        margin-bottom: 10px;
      }
    }
  }
}
