@import "../custom";

.landing-page {
  .logo {
    width: 806px;
    height: 311px;
    object-fit: cover;

    @media only screen and (max-width: 805px) {
      width: 100%;
    }
  }

  .section {
    margin: 50px 0;

    h2 {
      margin-bottom: 30px;
    }
  }

  .about {
    ul {
      text-align: left;
      list-style: none;

      li {
        margin-bottom: 14px;

        .pointer {
          margin-right: 10px;
          color: $primary;
        }
      }
    }
  }

  .expertise-boxes {
    display: flex;
    flex-wrap: wrap;

    .expertise-box {
      width: 33.3333%;
      padding: 40px;
      border: 1px solid rgb(241, 244, 246);

      &:hover {
        box-shadow: rgba(39, 79, 117, 0.2) 0px 40px 90px -30px;
      }

      .expertise-img-container {
        .expertise-img {
          width: 84px;
          height: 84px;
        }
      }
    }
  }

  .memberships-and-certifications {
    justify-content: center;

    h2 {
      margin-bottom: 40px;
    }

    img {
      max-height: 90px;
      margin: 0 20px;
    }
  }

  .contact {
    padding-top: 40px;
    background-color: $primary;
    color: #fff;

    h2 {
      color: #fff;
    }

    .col-map {
      display: flex;
      justify-content: flex-end;
      padding-right: 50px;

      .map-info-window {
        color: initial;
        font-weight: bold;
      }
    }

    .col-contact {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      padding-left: 50px;

      .address {
        width: 337px;
        text-align: center;
        margin-bottom: 50px;
      }

      .contact-form {
        width: 337px;

        .form-control {
          margin-bottom: 5px;
        }

        form {
          display: flex;
          flex-flow: column;
          
          button[type="submit"] {
            align-self: flex-end;
          }
        }
      }
    }
  }
}